.app {
    height: 100vh;
    width: 100vw;
    background-image: url("./background.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
	flex-direction: column;
    justify-content: center;
	align-items: center;
    text-align: center;
	user-select: none;
	font-family: "redhat";
	color: aliceblue;
}

.title{
	font-size: 5rem;
	text-transform: uppercase;
	font-weight: 500;
}

.text {
	font-size: 3rem;
	text-transform: uppercase;
	font-weight: 300;
	font-style: italic;
}

.footer {
	position: fixed;
	bottom: 50px;
	font-weight: 500;
	font-style: italic;
}



@font-face {
    font-family: "redhat";
    src: url("./fonts/RedHatDisplay-VariableFont_wght.ttf");
}
